exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-credits-index-jsx": () => import("./../../../src/pages/credits/index.jsx" /* webpackChunkName: "component---src-pages-credits-index-jsx" */),
  "component---src-pages-download-index-jsx": () => import("./../../../src/pages/download/index.jsx" /* webpackChunkName: "component---src-pages-download-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-news-index-jsx": () => import("./../../../src/pages/news/index.jsx" /* webpackChunkName: "component---src-pages-news-index-jsx" */),
  "component---src-pages-privacy-index-jsx": () => import("./../../../src/pages/privacy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-index-jsx" */),
  "component---src-pages-resume-jsx": () => import("./../../../src/pages/resume.jsx" /* webpackChunkName: "component---src-pages-resume-jsx" */),
  "component---src-pages-subj-index-jsx": () => import("./../../../src/pages/subj/index.jsx" /* webpackChunkName: "component---src-pages-subj-index-jsx" */),
  "component---src-pages-tags-index-jsx": () => import("./../../../src/pages/tags/index.jsx" /* webpackChunkName: "component---src-pages-tags-index-jsx" */),
  "component---src-pages-terms-index-jsx": () => import("./../../../src/pages/terms/index.jsx" /* webpackChunkName: "component---src-pages-terms-index-jsx" */),
  "component---src-templates-blog-index-jsx": () => import("./../../../src/templates/blog/index.jsx" /* webpackChunkName: "component---src-templates-blog-index-jsx" */),
  "component---src-templates-lesson-section-jsx": () => import("./../../../src/templates/lesson/section.jsx" /* webpackChunkName: "component---src-templates-lesson-section-jsx" */),
  "component---src-templates-open-index-jsx": () => import("./../../../src/templates/open/index.jsx" /* webpackChunkName: "component---src-templates-open-index-jsx" */),
  "component---src-templates-post-post-jsx": () => import("./../../../src/templates/post/post.jsx" /* webpackChunkName: "component---src-templates-post-post-jsx" */),
  "component---src-templates-subj-index-jsx": () => import("./../../../src/templates/subj/index.jsx" /* webpackChunkName: "component---src-templates-subj-index-jsx" */),
  "component---src-templates-tags-index-jsx": () => import("./../../../src/templates/tags/index.jsx" /* webpackChunkName: "component---src-templates-tags-index-jsx" */),
  "component---src-templates-tiny-index-jsx": () => import("./../../../src/templates/tiny/index.jsx" /* webpackChunkName: "component---src-templates-tiny-index-jsx" */)
}

